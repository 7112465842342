import React, { useState } from 'react';
import PropTypes from "prop-types"
import axios from 'axios'
import { passCsrfToken } from '../util/helpers'
import Notify from "./Notify"

const FavoriteWorkout = (props) => {  

  const [favorited, setFavorited] = useState(props.favorited);
  const [favoriteId, setFavoriteId] = useState(props.favoriteId);
  const [loading, setLoading] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState("");
  const [notifyStyle, setNotifyStyle] = useState("success");
  
  // TBD ADDING A LOADER WHILE PROCESSING

  React.useEffect(() => {
    passCsrfToken(document, axios);
  });

  
  const addFavoriteWorkout = () => {
    axios.post(`/users/${props.userId}/favorites`, {
        workout_id: props.workoutId,
      })
      .then((response) => {
        setNotifyMessage("Added to your favorites")
        setNotifyStyle("success")
        setFavorited(true);
        setFavoriteId(response.data.favorite.id)
      })
     .catch((error) => {
     })
     .then(() => {
     });
  }

  const unFavoriteWorkout = () => {
    axios.delete(`/users/${props.userId}/favorites/${favoriteId}`)
      .then((response) => {
        setNotifyMessage("Removed from favorites")
        setNotifyStyle("success")
        setFavorited(false);
        setFavoriteId(0);
      })
     .catch((error) => {
      setNotifyMessage(error.message)
      setNotifyStyle("error")
     })
     .then(() => {
     });

  }

  const renderFavoriteIcon = () => {
    if (loading) {
      // icon and spin to be added
    } else {
      if (favorited) {
        return (
          <i className="icon-star"></i>
        )
      } else {
        return (
          <i className="icon-star-o"></i>
        )
      }
    }
  }

  const handleClick = () => {
    if (favorited) {
      unFavoriteWorkout()
    } else {
      addFavoriteWorkout()
    }
  }


  return (
    <React.Fragment>
      <Notify message={notifyMessage} style={notifyStyle} />
      <button className="favorite_workout" onClick={handleClick}>
        {renderFavoriteIcon()}
      </button>
    </React.Fragment>
  )
  
}

FavoriteWorkout.defaultProps = {
  favorited: false,
  favoriteId: 0,  
};


FavoriteWorkout.propTypes = {
  favorited: PropTypes.bool.isRequired,
  workoutId: PropTypes.number.isRequired,
  favoriteId: PropTypes.number,
  userId: PropTypes.number.isRequired,
};

export default FavoriteWorkout
