import React, { useState } from 'react';
import { Table, User, Body, Title, Card, Background, Collapsible, FixedConfirmationToast, } from 'playbook-ui';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Outlet, useOutletContext, useNavigate, useParams } from 'react-router-dom';
// TABLE COLUMNS
var columnHelper = createColumnHelper();
var columns = [
    // columnHelper.display({
    //   id: "checkbox",
    //   header: ({ table }) => (
    //     <Checkbox
    //       checked={table.getIsAllRowsSelected()}
    //       indeterminate={table.getIsSomeRowsSelected()}
    //       onChange={table.getToggleAllRowsSelectedHandler()}
    //     />
    //   ),
    //   cell: ({ row }) => (
    //     <Checkbox
    //       checked={row.getIsSelected()}
    //       disabled={!row.getCanSelect()}
    //       onChange={row.getToggleSelectedHandler()}
    //     />
    //   ),
    // }),
    columnHelper.accessor('name', {
        header: function () { return 'Member'; },
        id: 'name',
        cell: function (props) {
            return (React.createElement(User, { avatarUrl: props.row.original.avatar, name: props.row.original.name }));
        }
    }),
    columnHelper.accessor('email', {
        header: function () { return 'Email'; },
        cell: function (info) { return info.renderValue(); },
    }),
    columnHelper.accessor('joinedAt', {
        header: 'Joined',
        cell: function (info) {
            return (React.createElement(Body, { text: info.renderValue() }));
        }
    }),
    columnHelper.accessor('id', {
        header: '',
        cell: function () {
            return (React.createElement(Title, { size: "3", color: "link" },
                React.createElement("i", { className: "icon icon-angle-right" })));
        }
    }),
];
function MembersListing(_a, _b) {
    var members = _a.members;
    var classes = _b.classes;
    // STATES
    var _c = React.useState({}), rowSelection = _c[0], setRowSelection = _c[1];
    var _d = useState(function () { return JSON.parse(members.toString()); }), data = _d[0], setData = _d[1];
    var _e = useState(null), user = _e[0], setUser = _e[1];
    var _f = useState(false), isOpen = _f[0], setIsOpen = _f[1];
    var _g = useState(0), deepLinkLoad = _g[0], setDeepLinkLoad = _g[1]; // Added to ensure only run on init
    var _h = useState(false), openToast = _h[0], setOpenToast = _h[1];
    var _j = useState(''), toastMessage = _j[0], setToastMessage = _j[1];
    var _k = useState('tip'), toastStyle = _k[0], setToastStyle = _k[1];
    var table = useReactTable({
        data: data,
        columns: columns,
        state: {
            rowSelection: rowSelection,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
    });
    var navigate = useNavigate();
    var memberId = useParams().memberId;
    var gymId = useParams().gymId;
    // FUNCTIONS
    var handleShowMember = function (rowData, cellData) {
        if (cellData.column.id != "checkbox") {
            setUser(rowData.original);
            navigate("/gyms/".concat(gymId, "/manage_members/").concat(rowData.original.id));
            setOpen(true);
            setDeepLinkLoad(1);
        }
    };
    var removeMember = function (memberId) {
        setData(data.filter(function (x) { return x.id !== Number(memberId); }));
    };
    var loadMember = function () {
        if (memberId && isOpen === false && deepLinkLoad === 0) {
            var memberData = data.find(function (x) { return x.id === Number(memberId); });
            if (memberData === undefined) {
                navigate("/gyms/".concat(gymId, "/manage_members"));
            }
            else {
                setOpen(true);
                setUser(memberData);
            }
            setDeepLinkLoad(1);
        }
    };
    React.useEffect(function () {
        loadMember();
    });
    var setOpen = function (open) {
        setIsOpen(open);
    };
    var setListingToast = function (open, message, style) {
        setOpenToast(open);
        setToastStyle(style);
        setToastMessage(message);
    };
    var handleCloseToast = function () {
        setOpenToast(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { margin: "md", padding: "none" },
            React.createElement(Outlet, { context: { isOpen: isOpen, setOpen: setOpen, removeMember: removeMember, setListingToast: setListingToast } }),
            React.createElement(Collapsible, { collapsed: true, padding: "none" },
                React.createElement(Collapsible.Main, { padding: "none", icon: "", className: "hide" }),
                React.createElement(Collapsible.Content, { className: "fadeIn", padding: "none" },
                    React.createElement(Body, { paddingY: "sm", paddingX: "md", text: "".concat(Object.keys(rowSelection).length, " Selected") }))),
            React.createElement(Table, { size: "sm", cursor: "pointer", className: "members-table", container: false, sticky: true, responsive: "none" },
                React.createElement("thead", { className: "rounded-top" }, table.getHeaderGroups().map(function (headerGroup) { return (React.createElement("tr", { key: headerGroup.id }, headerGroup.headers.map(function (header) { return (React.createElement("th", { key: header.id }, header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext()))); }))); })),
                React.createElement("tbody", null, table.getRowModel().rows.map(function (row) { return (React.createElement(Background, { backgroundColor: row.getIsSelected() == true ? "warning_subtle" : "white", tag: 'tr', key: row.id }, row.getVisibleCells().map(function (cell) { return (React.createElement("td", { key: cell.id, className: cell.column.id, onClick: function () { return handleShowMember(row, cell); } }, flexRender(cell.column.columnDef.cell, cell.getContext()))); }))); })))),
        React.createElement(FixedConfirmationToast, { autoClose: 4000, className: "fadeIn", closeable: true, horizontal: 'center', vertical: 'top', onClose: handleCloseToast, open: openToast, status: toastStyle, text: toastMessage })));
}
;
export default MembersListing;
export function useUser() {
    return useOutletContext();
}
