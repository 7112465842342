import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types"

const Notify = (props) => {  
  
  // Keeping this simple until I recode more of the page to react

  React.useEffect(() => {
    if (props.message.length > 0) {
      $('body').notify({
        style: props.style,
        position: "top",
        message: props.message
      })  
    } 
  });
  
  return (
    <React.Fragment></React.Fragment>
  )
  
}


Notify.defaultProps = {
  message: '',
  autoDelete: true,
  dismissTime: 3000,
  style: "success"
};

Notify.propTypes = {
  message: PropTypes.string,
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number,
  style: PropTypes.string
};

export default Notify
