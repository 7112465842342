import React, { useState } from 'react';
import { useUser } from "./MembersListing";
import { Dialog, SectionSeparator, User, Button, Caption, Image, Avatar, Flex, FlexItem, Timestamp, Card, Title, Date as PBDate, FixedConfirmationToast, Badge, Body, PbReactPopover } from 'playbook-ui';
import axios from 'axios';
import { useNavigate, useLoaderData, useParams } from 'react-router-dom';
function MemberDetail() {
    var _a = useState(false), openToast = _a[0], setOpenToast = _a[1];
    var _b = useState(''), toastMessage = _b[0], setToastMessage = _b[1];
    var _c = useState('tip'), toastStyle = _c[0], setToastStyle = _c[1];
    var _d = useLoaderData(), initialMember = _d.member, initialRestrictedView = _d.restricted_view;
    var _e = useState(initialMember), member = _e[0], setMember = _e[1];
    var _f = useState(initialRestrictedView), restrictedView = _f[0], setRestrictedView = _f[1];
    var _g = useState(false), isConfirmOpen = _g[0], setIsConfirmOpen = _g[1];
    var closeConfirm = function () { return setIsConfirmOpen(false); };
    var _h = useState(false), showRemoveAdminButton = _h[0], setShowRemoveAdminButton = _h[1];
    var navigate = useNavigate();
    var _j = useParams(), gymId = _j.gymId, memberId = _j.memberId;
    var _k = useUser(), isOpen = _k.isOpen, setOpen = _k.setOpen, removeMember = _k.removeMember, setListingToast = _k.setListingToast;
    var handleCloseToast = function () {
        setOpenToast(false);
    };
    var close = function () {
        setOpen(false);
        setTimeout(function () {
            navigate(-1);
        }, 200);
    };
    var checkInWorkouts = function () {
        axios.post("/gyms/".concat(gymId, "/checkin_workouts"), {
            user: memberId,
        })
            .then(function (response) {
            setOpenToast(true);
            setToastStyle('success');
            setToastMessage(response.data.message);
        })
            .catch(function (error) {
            var _a, _b;
            setOpenToast(true);
            setToastStyle('error');
            setToastMessage(((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || 'Error checking in workouts');
        });
    };
    var handleGymAdmin = function (e) {
        e.preventDefault();
        if (member.gym_admin) {
            setShowRemoveAdminButton(true);
            setIsConfirmOpen(true);
        }
        else {
            makeGymAdmin();
        }
    };
    var handleRemoveFromClass = function (gymClass) {
        axios.patch("/gyms/".concat(gymId, "/classes/").concat(gymClass.id, "/remove_class_member/").concat(member.id))
            .then(function (response) {
            setOpenToast(true);
            setToastStyle('success');
            setIsConfirmOpen(false);
            setMember(response.data.member);
            setToastMessage('Successfully Removed from Class');
        })
            .catch(function (error) {
            var _a, _b;
            setOpenToast(true);
            setToastStyle('error');
            setToastMessage(((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) || 'Error removing from class');
        });
    };
    var handleAddToClass = function (gymClass) {
        axios.patch("/gyms/".concat(gymId, "/classes/").concat(gymClass.id, "/add_class_member/").concat(member.id), {
            user: memberId,
        })
            .then(function (response) {
            setOpenToast(true);
            setToastStyle('success');
            setIsConfirmOpen(false);
            setMember(response.data.member);
            setToastMessage('Successfully Added To Class');
        })
            .catch(function (error) {
            setOpenToast(true);
            setToastStyle('error');
            setToastMessage(error.data.error);
        })
            .then(function () {
        });
        setShowClassPopover(false);
    };
    var makeGymAdmin = function () {
        axios.post("/gyms/".concat(gymId, "/make_gym_admin"), {
            user: memberId,
        })
            .then(function (response) {
            setMember(response.data.member);
            setOpenToast(true);
            setToastStyle('success');
            setToastMessage('Successfully made an Administrator');
        })
            .catch(function (error) {
            setOpenToast(true);
            setToastStyle('error');
            setToastMessage(error.data.error);
        })
            .then(function () {
        });
    };
    var removeGymAdmin = function (e) {
        e.preventDefault();
        axios.patch("/gyms/".concat(gymId, "/remove_gym_admin"), {
            user: memberId,
        })
            .then(function (response) {
            setIsConfirmOpen(false);
            setMember(response.data.member);
            setOpenToast(true);
            setToastStyle('success');
            setToastMessage('Successfully removed as an Administrator');
        })
            .catch(function (error) {
            setOpenToast(true);
            setToastStyle('error');
            setToastMessage(error.data.error);
        })
            .then(function () {
        });
    };
    var handleRemoveFromGym = function (e) {
        e.preventDefault();
        setShowRemoveAdminButton(false);
        setIsConfirmOpen(true);
    };
    var removeFromGym = function () {
        axios.delete("/gyms/".concat(gymId, "/remove_user/").concat(memberId))
            .then(function (response) {
            setListingToast(true, 'Successfully removed from the box', 'success');
            setIsConfirmOpen(false);
            removeMember(memberId);
            close();
        })
            .catch(function (error) {
            setOpenToast(true);
            setToastStyle('error');
            setToastMessage('Failed to remove from gym');
            setIsConfirmOpen(false);
        });
    };
    var _l = useState(false), showClassPopover = _l[0], setShowClassPopover = _l[1];
    var handleToggleClassPopover = function (e) {
        e.preventDefault();
        setShowClassPopover(!showClassPopover);
    };
    var classesToAdd = member === null || member === void 0 ? void 0 : member.gym_classes.filter(function (_a) {
        var id = _a.id;
        return !new Set(member === null || member === void 0 ? void 0 : member.member_classes.map(function (_a) {
            var id = _a.id;
            return id;
        })).has(id);
    });
    var addToClassButton = (React.createElement(React.Fragment, null, classesToAdd.length > 0 ?
        (React.createElement("a", { href: "js:;", onClick: handleToggleClassPopover, className: "hover_background_hover_light border_radius_lg cursor_pointer display_flex justify_content_center mt_md" },
            React.createElement(Title, { size: 4, padding: "xs" },
                React.createElement(Body, { tag: "span", color: "light", marginRight: "xs" },
                    React.createElement("i", { className: "icon icon-plus" })),
                "Add to Class"))) : ("")));
    var userEmailSubtitle = (React.createElement(React.Fragment, null,
        React.createElement("a", { href: "mailto:".concat(member === null || member === void 0 ? void 0 : member.email), className: "hover_background_hover_light border_radius_lg" },
            React.createElement(Body, { tag: "span", color: "light", text: member === null || member === void 0 ? void 0 : member.email }),
            React.createElement(Body, { tag: "span", color: "link", marginLeft: "xxs" },
                React.createElement("i", { className: "icon icon-open" }))),
        (member === null || member === void 0 ? void 0 : member.gym_admin) ?
            (React.createElement(React.Fragment, null,
                React.createElement("br", null),
                React.createElement(Badge, { className: "fadeIn", text: "BOX ADMINISTRATOR", variant: "success", marginTop: "xxs", rounded: true }))) : ("")));
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { fullHeight: true, onClose: close, opened: isOpen, closeable: true, size: "sm", placement: "right" },
            React.createElement(Dialog.Body, { className: "members-detail" },
                React.createElement("div", null,
                    React.createElement("div", { className: "img-back-contain rounded members-detail-cover mb_sm bg-sky" },
                        React.createElement(Image, { alt: "", url: member === null || member === void 0 ? void 0 : member.cover, className: "img-back" }),
                        React.createElement("div", { className: 'img-back-contain text-white' },
                            React.createElement("a", { href: "js:;", onClick: close, className: "text-white members-detail-close" },
                                React.createElement("i", { className: "icon icon-times" })),
                            React.createElement(Avatar, { imageUrl: member === null || member === void 0 ? void 0 : member.avatar_large, name: member === null || member === void 0 ? void 0 : member.name, size: "xl", className: " members-detail-avatar" }))),
                    React.createElement("div", { className: 'members-detail-userdetails' },
                        React.createElement(User, { name: member === null || member === void 0 ? void 0 : member.name, subtitle: userEmailSubtitle, className: "members-detail-user", marginBottom: "xl" }),
                        React.createElement(Flex, { gap: "xs", marginBottom: "md" },
                            React.createElement(FlexItem, { flex: 1 },
                                React.createElement(Caption, { text: "Scores", marginBottom: "sm" }),
                                React.createElement(Title, { text: member === null || member === void 0 ? void 0 : member.scores, size: 3, bold: false })),
                            React.createElement(FlexItem, { flex: 1 },
                                React.createElement(Caption, { text: "PR's", marginBottom: "sm" }),
                                React.createElement(Title, { text: member === null || member === void 0 ? void 0 : member.prs, size: 3, bold: false })),
                            React.createElement(FlexItem, { flex: 1 },
                                React.createElement(Caption, { text: "Joined", marginBottom: "sm" }),
                                React.createElement(Body, null,
                                    React.createElement(PBDate, { unstyled: true, value: member === null || member === void 0 ? void 0 : member.joined_at })),
                                React.createElement(Timestamp, { hideUpdated: true, showUser: false, variant: "elapsed", timestamp: member === null || member === void 0 ? void 0 : member.joined_at })))),
                    restrictedView ? (React.createElement(React.Fragment, null,
                        React.createElement(SectionSeparator, null),
                        React.createElement(Flex, { flexDirection: "column", paddingY: "lg", alignItems: "stretch" },
                            React.createElement(Caption, { text: "Member Workout Checkin", marginBottom: "xs" }),
                            React.createElement(Body, { text: "You need a subscription to check in members." })))) : (React.createElement(React.Fragment, null,
                        React.createElement(SectionSeparator, null),
                        React.createElement(Flex, { flexDirection: "column", alignItems: "center", paddingY: "lg" },
                            React.createElement(Button, { className: "btn-primary-outline full rounded", onClick: checkInWorkouts },
                                React.createElement("i", { className: "icon icon-check-o mr_xs" }),
                                "Check in",
                                React.createElement("span", { className: 'm_hide' },
                                    " ", member === null || member === void 0 ? void 0 :
                                    member.first_name)),
                            React.createElement(Caption, { size: "xs", marginTop: "xs" },
                                "This will count ", member === null || member === void 0 ? void 0 :
                                member.first_name,
                                " as doing the most recent workouts")))),
                    restrictedView ? (React.createElement(React.Fragment, null,
                        React.createElement(SectionSeparator, null),
                        React.createElement(Flex, { flexDirection: "column", paddingY: "lg", alignItems: "stretch" },
                            React.createElement(Caption, { text: "".concat(member === null || member === void 0 ? void 0 : member.first_name, "'s Classes"), marginBottom: "xs" }),
                            React.createElement(Body, { text: "You need a subscription to manage classes." })))) : (React.createElement(React.Fragment, null, (member === null || member === void 0 ? void 0 : member.gym_classes.length) > 0 ? (React.createElement(React.Fragment, null,
                        React.createElement(SectionSeparator, null),
                        React.createElement(Flex, { flexDirection: "column", paddingY: "lg", alignItems: "stretch" },
                            React.createElement(Caption, { text: "".concat(member === null || member === void 0 ? void 0 : member.first_name, "'s Classes"), marginBottom: "md" }),
                            (member === null || member === void 0 ? void 0 : member.member_classes.length) == 0 ? (React.createElement(Body, { text: "".concat(member === null || member === void 0 ? void 0 : member.first_name, " is not in any classes") })) : (member === null || member === void 0 ? void 0 : member.member_classes.map(function (gymClass, index) { return (React.createElement(Flex, { flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "xxs", key: index },
                                React.createElement(FlexItem, { flex: 1 },
                                    React.createElement(Body, { text: gymClass.name })),
                                React.createElement("a", { href: "js:;", onClick: function (e) { e.preventDefault(); handleRemoveFromClass(gymClass); }, className: "hover_background_hover_light border_radius_lg px_xxs" },
                                    React.createElement(Caption, { tag: "span", color: "link", size: "xs", text: "Remove" }),
                                    React.createElement(Body, { color: "link", tag: "span", marginLeft: "xxs" },
                                        React.createElement("i", { className: "icon icon-leave" }))))); })),
                            React.createElement(PbReactPopover, { closeOnClick: "inside", offset: true, placement: "top", reference: addToClassButton, show: showClassPopover, shouldClosePopover: handleToggleClassPopover, zIndex: "10000", padding: "none" }, (classesToAdd.map(function (gymClass, index) { return (React.createElement(React.Fragment, null,
                                React.createElement(Flex, { flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "xxs", padding: "xs", key: index },
                                    React.createElement(FlexItem, { flex: 1 },
                                        React.createElement(Body, { text: gymClass.name })),
                                    React.createElement("a", { href: "js:;", onClick: function (e) { e.preventDefault(); handleAddToClass(gymClass); }, className: "hover_background_hover_light border_radius_lg px_xxs ml_lg" },
                                        React.createElement(Caption, { tag: "span", color: "link", size: "xs", text: "Add to Class" }),
                                        React.createElement(Body, { color: "link", tag: "span", marginLeft: "xxs" },
                                            React.createElement("i", { className: "icon icon-plus" })))),
                                React.createElement(SectionSeparator, null))); })))))) : (React.createElement(Body, { color: "link", tag: "span", marginLeft: "xxs" })))),
                    React.createElement(SectionSeparator, null),
                    React.createElement(Flex, { flexDirection: "column", paddingY: "lg", alignItems: "stretch" },
                        React.createElement(Caption, { text: "Other Actions", marginBottom: "sm" }),
                        React.createElement(Card, { display: "block", padding: "none" },
                            React.createElement("a", { href: "js:;", onClick: handleGymAdmin, className: "hover_background_hover_light cursor_pointer" },
                                React.createElement(Title, { size: 4, padding: "sm" },
                                    React.createElement(Body, { tag: "span", color: (member === null || member === void 0 ? void 0 : member.gym_admin) ? "error" : "success", marginRight: "xs" },
                                        React.createElement("i", { className: "icon icon-user-admin" })),
                                    (member === null || member === void 0 ? void 0 : member.gym_admin) ? "Remove as Box Administrator" : "Make Box Administrator")),
                            React.createElement(SectionSeparator, null),
                            React.createElement("a", { href: "js:;", onClick: handleRemoveFromGym, className: "hover_background_hover_light cursor_pointer" },
                                React.createElement(Title, { size: 4, padding: "sm" },
                                    React.createElement(Body, { tag: "span", color: "error", marginRight: "xs" },
                                        React.createElement("i", { className: "icon icon-leave" })),
                                    "Remove from the Box"))))),
                React.createElement(FixedConfirmationToast, { autoClose: 4000, className: "fadeIn", closeable: true, horizontal: 'center', vertical: 'top', onClose: handleCloseToast, open: openToast, status: toastStyle, text: toastMessage }))),
        React.createElement(Dialog, { status: "delete", onClose: closeConfirm, opened: isConfirmOpen, size: "sm", title: "Are you sure?" },
            React.createElement(Flex, { flexDirection: "column", paddingX: "sm", paddingBottom: "sm", alignItems: "stretch" },
                showRemoveAdminButton ? (React.createElement(Button, { text: "Yes, Remove Admin", className: "btn-danger-solid small", onClick: removeGymAdmin, marginBottom: "xs" })) : (React.createElement(Button, { text: "Yes, Remove from Box", className: "btn-danger-solid small", onClick: removeFromGym, marginBottom: "xs" })),
                React.createElement(Button, { text: "Cancel", className: "btn-primary-outline small", onClick: closeConfirm })))));
}
export default MemberDetail;
